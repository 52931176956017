import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        


        primary: "#0354e0",
        whiteColorVariant1: "#a6c6ff",
        greenColorVariant1: "#cefcce",
        greenColorVariant2: "#E7FFF3",
        blueColorVariant1: "#ccd7eb",
        blueColorVariant2: "#003a9f",
        blueColorVariant3: "#f0f8ff",
        redColorVariant1: "#FFE5E5",
        redColorVariant2: "#ff9999",
        greyColorVariant1: "#333536",
        greyColorVariant2: "#D2D2D2",
        greyColorVariant3: "#f4f4f4",
        greyColorVariant4: "#435e8e"
      },
    },
  },
});
