export const formatphoneNumber = {
  data: () => ({}),
  methods: {
    formatPhoneNumber(phoneNumber) {
      if (phoneNumber) {
        phoneNumber = phoneNumber.replace(/[^\d]/g, "");

        if (phoneNumber.length > 3) {
          phoneNumber = `(${phoneNumber.slice(0, 3)})` + " " + phoneNumber.slice(3);
        }
        if (phoneNumber.length > 9) {
          phoneNumber = phoneNumber.slice(0, 9) + "-" + phoneNumber.slice(9);
        }
        return phoneNumber;
      }
    },
  },
};
