<template>
  <v-app :style="{ background: customBackgroundColor }">
    <v-main>
      <Snackbar :SnackBarComponent="SnackBarComponent" />
      <Overlay :overlay="overlay" />
      <v-row no-gutters class="fill-height" align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card-text class="text-center">
            <v-card v-if="current_view == 'CARD'" class="elevation-0 pa-8">
              <div class="fontSize25px font-weight-bold primary--text">DealData</div>
              <div class="fontSize30px fontWeightVariant1 lineHeightVariant2 black--text mt-4">
                Fast, Secure and Convenient<br />
                Deal Submission & Analysis
              </div>
              <div class="mt-4">
                <v-btn
                  depressed
                  @click="enterStepOneMethod()"
                  class="text-capitalize borderRadiusVariant2 pa-6"
                  color="primary"
                  >Accept Invite</v-btn
                >
              </div>
              <v-row class="mt-4">
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined class="pa-4 borderRadiusVariant1" height="100%">
                    <v-img width="40px" src="@/assets/Money.png"></v-img>
                    <div class="text-left fontSize15px font-weight-bold">Submit Deals Fast</div>
                    <div class="text-left fontSize12px">
                      Submit deal-level information quickly through our step-by-step entry process or via bulk upload.
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined class="pa-4 borderRadiusVariant1" height="100%">
                    <v-img width="40px" src="@/assets/Security.png"></v-img>
                    <div class="text-left fontSize15px font-weight-bold">Aggregate and Individual Deal Analysis</div>
                    <div class="text-left fontSize12px">
                      Review and analyze deal information on an individual on an individual and aggregate level.
                    </div>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="4">
                  <v-card outlined class="pa-4 borderRadiusVariant1" height="100%">
                    <v-img width="40px" src="@/assets/Phone.png"></v-img>
                    <div class="text-left fontSize15px font-weight-bold">Secure and Confidential</div>
                    <div class="text-left fontSize12px">Security and confidentiality built from the ground up.</div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
            <v-card align="center" color="transparent" v-if="current_view == 'STEPPER'" class="elevation-0">
              <v-card class="pa-8" max-width="500px">
                <div
                  v-if="loadingActivatingAccount == false"
                  class="text-left fontSize30px fontWeightVariant1 lineHeightVariant2 black--text"
                >
                  {{ currentStepperObject.stepper_title }}
                </div>
                <div
                  v-if="loadingActivatingAccount == false && currentStepperObject.stepper_sub_title != ''"
                  class="text-left fontSize16px greyColorVariant1--text mt-2"
                >
                  {{ currentStepperObject.stepper_sub_title }}
                </div>
                <v-window v-model="loginStepper" align="left">
                  <v-window-item :value="1">
                    <v-form class="mt-4" ref="formStep1">
                      <div class="font-weight-bold mb-2 mt-n2">Email Address</div>
                      <v-text-field
                        dense
                        outlined
                        readonly
                        filled
                        v-model="user_details.user_email_id"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Invalid Email',
                        ]"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                      <div class="font-weight-bold mb-2 mt-n2">Create Password</div>
                      <v-text-field
                        dense
                        outlined
                        :type="isPwdLoginCreate ? 'text' : 'password'"
                        v-model="user_details.user_create_password"
                        @click:append="isPwdLoginCreate = !isPwdLoginCreate"
                        :append-icon="isPwdLoginCreate ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 8) || 'Password Must Have Min. 8 characters',
                          (v) =>
                            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(v) || 'Requires Special Character',
                        ]"
                        placeholder="8 Characters"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                      <div class="font-weight-bold mb-2 mt-n2">Confirm Password</div>
                      <v-text-field
                        dense
                        outlined
                        :type="isPwdLoginConfirm ? 'text' : 'password'"
                        v-model="user_details.user_confirm_password"
                        @click:append="isPwdLoginConfirm = !isPwdLoginConfirm"
                        :append-icon="isPwdLoginConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 8) || 'Password Must Have Min. 8 characters',
                          (v) =>
                            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(v) || 'Requires Special Character',
                        ]"
                        placeholder="8 Characters"
                        class="borderRadiusVariant1"
                      ></v-text-field>
                    </v-form>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        depressed
                        :loading="loadingStepper1"
                        @click="validateStepperOneMethod()"
                        class="text-capitalize borderRadiusVariant2 pa-6"
                      >
                        Activate
                      </v-btn>
                    </v-card-actions>
                  </v-window-item>
                  <v-window-item :value="2">
                    <v-form class="mt-4" ref="formStep2">
                      <div class="font-weight-bold mb-2">Verification Code</div>
                      <v-otp-input
                        :length="6"
                        type="number"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) => (v && v.length >= 6) || 'Password Must Have Min. 6 characters',
                          (v) => /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(v) || 'Numeric only',
                        ]"
                        v-model="verification_code_details.verification_code"
                      ></v-otp-input>
                      <!-- <div v-if="ResendOTPCounter !== 0" class="">Resend OTP available in {{ ResendOTPCounter }}</div> -->

                      <!-- <v-text-field
                        dense
                        outlined
                        v-model="verification_code_details.verification_code"
                        :rules="[
                          (v) => !!v || 'Required',
                          (v) =>
                            (v && v.length >= 6) ||
                            'Password Must Have Min. 6 characters',
                          (v) =>
                            /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(
                              v
                            ) || 'Numeric only',
                        ]"
                        placeholder="6 Digit Code"
                        class="borderRadiusVariant1"
                      ></v-text-field> -->
                    </v-form>
                    <v-card-actions class="pa-0 ma-0">
                      <v-btn
                        color="primary"
                        block
                        :loading="loadingStepper2"
                        @click="validateStepperTwoMethod()"
                        class="text-capitalize pa-6"
                      >
                        Verify
                      </v-btn>
                    </v-card-actions>
                    <v-row no-gutters class="mt-2">
                      <v-col cols="12" align="center">
                        <v-btn
                          text
                          :disabled="ResendOTPCounter !== 0"
                          :loading="loadingStepper1"
                          class="pa-0 ml-0"
                          :class="ResendOTPCounter === 0 ? 'text-decoration-underline' : ''"
                          @click="validateStepperOneMethod()"
                          >Resend OTP
                          <span v-if="ResendOTPCounter !== 0" class="pl-2">{{ ResendOTPCounter }}</span></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-card>
          </v-card-text>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>
<script>
import { Auth } from "aws-amplify";
import { GetVerificationCodeForActivatingMembers } from "@/mixins/RestAPIs/GetVerificationCodeForActivatingMembers.js";
import { VerifyCodeAndActivateMember } from "@/mixins/RestAPIs/VerifyCodeAndActivateMember.js";
import USStatesList from "@/JsonFiles/USStatesList.json";
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: {
    Snackbar,
    Overlay,
  },
  mixins: [GetVerificationCodeForActivatingMembers, VerifyCodeAndActivateMember],
  data: () => ({
    intervalId: null,
    loginStepper: 1,
    contact_count: 1,
    ResendOTPCounter: 0,
    current_view: "STEPPER",
    customBackgroundColor: "#f0f8ff",
    loadingStepper1: false,
    loadingStepper2: false,
    loadingStepper5: false,
    isPwdLoginCreate: false,
    isPwdLoginConfirm: false,
    loadingActivatingAccount: false,
    overlay: false,

    user_details: {
      user_email_id: "",
      user_create_password: "",
      user_confirm_password: "",
    },
    SnackBarComponent: {},
    currentStepperObject: {},
    currentTeamDetailsObject: {},
    verification_code_details: {
      verification_code: "",
    },
    stepperItems: [
      {
        stepper_title: "Let's activate your DealData account",
        stepper_sub_title: "Please provide a strong password below to activate your account",
      },
      {
        stepper_title: "Please enter the code we sent to your email address",
        stepper_sub_title:
          // "We require two-factor authentication for security reasons",
          "For security reasons, we have sent you a code",
      },
      {
        stepper_title: "Your Account is Activated! Please confirm your bank profile below is accurate",
        stepper_sub_title: "",
      },
      {
        stepper_title: "Please add your syndication contacts",
        stepper_sub_title: "",
      },
      {
        stepper_title: "Please add your bank's purchasing preferences",
        stepper_sub_title: "",
      },
    ],
  }),
  watch: {
    "user_details.user_email_id"(val) {
      this.user_details.user_email_id = val.toLowerCase();
    },
  },
  async mounted() {
    this.loginStepper = 1;
    this.current_view = "CARD";
    this.USStatesList = USStatesList;
    this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ResendOTPCounterMethod() {
      this.intervalId = setInterval(() => {
        this.ResendOTPCounter--;
        if (this.ResendOTPCounter <= 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    async enterStepOneMethod() {
      let UserEmailId = atob(this.$route.params.EditorViewerSignUp);
      this.user_details.user_email_id = UserEmailId.split("=")[1];
      this.current_view = "STEPPER";
      this.loginStepper = 1;
      if (this.$refs.formStep1) {
        this.$refs.formStep1.reset();
      }
    },
    async validateStepperOneMethod() {
      if (this.$refs.formStep1.validate()) {
        if (this.user_details.user_create_password == this.user_details.user_confirm_password) {
          this.loadingStepper1 = true;
          let result = await this.GetVerificationCodeForActivatingMembersMethod(this.user_details);
          this.loadingStepper1 = false;
          if (result.data && result.data.status == "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: "OTP sent successfully",
            };
            this.ResendOTPCounter = 120;
            this.ResendOTPCounterMethod();
            this.loginStepper = 2;
            this.currentStepperObject = this.stepperItems[this.loginStepper - 1];
            if (this.$refs.formStep2) {
              this.$refs.formStep2.reset();
            }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: result.data.errorMessage,
            };
          }
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async validateStepperTwoMethod() {
      if (this.$refs.formStep2.validate()) {
        this.loadingStepper2 = true;
        let result = await this.VerifyCodeAndActivateMemberMethod(
          this.user_details.user_email_id,
          this.verification_code_details.verification_code
        );
        this.loadingStepper2 = false;
        if (result.data && result.data.status == "Success") {
          let sign_in = {
            user_email_id: this.user_details.user_email_id,
            user_password: this.user_details.user_create_password,
          };
          this.signInMethod(sign_in);
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: result.data.errorMessage,
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async signInMethod(sign_in) {
      this.overlay = true;
      try {
        this.loadingSignIn = true;
        this.qrCodeRequired = false;
        this.userObject = await Auth.signIn(sign_in.user_email_id, sign_in.user_password);
        this.$store.commit("SET_USEREMAIL", sign_in.user_email_id);
        if (this.userObject.challengeName === "MFA_SETUP") {
          const res = await Auth.setupTOTP(this.userObject);
          this.qrCodeURL = "otpauth://totp/AWSCognito:" + sign_in.user_email_id + "?secret=" + res + "&issuer=Cognito";
          this.qrCodeRequired = true;
        } else if (this.userObject.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.qrCodeRequired = false;
        } else {
          this.pushToHomeRouteMethod();
        }
        this.loadingSignIn = false;
        this.overlay = true;
      } catch (error) {
        this.loadingSignIn = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.message,
        };
      }
    },
    pushToHomeRouteMethod() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In",
      };
      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;
        this.$router.push("/MainHomePages/LandingPage");
      }, 1000);
    },
  },
};
</script>

<style>
.fill-height {
  height: 100vh;
}
</style>
