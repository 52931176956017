<template>
  <div class="background" ref="pdfContent">
    <v-row no-gutters>
      <v-col cols="12" md="7" sm="12" xs="12">
        <div class="bg-img">
          <v-card flat tile>
            <div class="leftSide">
              <v-container fluid fill-height color="grey lighten-3">
                <v-layout align-center justify-center>
                  <v-flex>
                    <div
                      class="text-center FontSize65px black--text lineUp"
                    ></div>
                    <div
                      class="text-center FontSize45px black--text lineUp2"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="12" md="5" sm="12" xs="12">
        <v-container fluid fill-height color="grey lighten-3">
          <Snackbar :SnackBarComponent="SnackBarComponent" />
          <v-layout align-center justify-center>
            <v-flex>
              <v-row>
                <v-col cols="12" xs="12" md="12" align="center">
                  <v-card
                    class="elevation-0"
                    max-width="500px"
                    color="transparent"
                  >
                    <v-card-text>
                      <v-card
                        class="elevation-0 ma-6"
                        align="left"
                        color="transparent"
                      >
                        <v-row>
                          <v-col cols="12" xs="12" sm="12" md="12" class="pb-0">
                            <div
                              class="fontSize25px fontWeightVariant1 white--text lineHeightVariant1"
                            >
                              {{ currentTitle }}
                            </div>
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="10"
                            align="left"
                            v-if="loginStepper != 5"
                          >
                            <v-img contain src="@/assets/dth.png"></v-img>
                          </v-col>
                        </v-row>
                        <v-window v-model="loginStepper">
                          <v-window-item :value="1">
                            <v-card-text class="pa-0">
                              <v-form
                                ref="formSignIn"
                                @submit.prevent
                                lazy-validation
                              >
                                <div class="font-weight-bold white--text my-3">
                                  Email ID
                                </div>
                                <v-text-field
                                  dense
                                  outlined
                                  class="borderRadiusVariant1"
                                  background-color="white"
                                  @keydown.enter="ValidateSignInForm()"
                                  @keydown.space="preventLeadingSpace"
                                  :rules="[
                                    (v) => !!v || 'Email ID is required',
                                  ]"
                                  v-model="sign_in.user_email_id"
                                ></v-text-field>
                                <div class="font-weight-bold white--text mb-3">
                                  Password
                                </div>
                                <v-text-field
                                  dense
                                  outlined
                                  class="borderRadiusVariant1"
                                  background-color="white"
                                  :type="isPwdLogin ? 'text' : 'password'"
                                  @click:append="isPwdLogin = !isPwdLogin"
                                  @keydown.space="preventLeadingSpace"
                                  @keydown.enter="ValidateSignInForm()"
                                  required
                                  :append-icon="
                                    isPwdLogin ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="[
                                    (v) => !!v || 'Required',
                                    (v) =>
                                      (v && v.length >= 8) ||
                                      'Password Must Have Min. 8 characters',
                                    (v) =>
                                      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(
                                        v
                                      ) || 'Requires Special Character',
                                  ]"
                                  v-model="sign_in.user_password"
                                ></v-text-field>
                                <v-card-actions class="py-0">
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    small
                                    text
                                    class="FontSize mt-n4 mr-n4 text-capitalize fontStyle"
                                    color="white"
                                    @click="loginStepper = 7"
                                    >Forgot Password ?</v-btn
                                  >
                                </v-card-actions>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="px-0">
                              <v-btn
                                :loading="loadingSignIn"
                                tile
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="ValidateSignInForm()"
                                >Sign In</v-btn
                              >
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="2">
                            <v-card-text class="pa-0">
                              <div class="font-weight-bold mt-6">
                                <v-card-actions v-if="qrCodeRequired">
                                  <v-card class="elevation-0">
                                    <qr-code
                                      :size="100"
                                      :text="qrCodeURL"
                                    ></qr-code>
                                  </v-card>
                                  <v-card class="elevation-0 mx-2 mt-n10">
                                    Scan this QR Code with your authenticator
                                    app. Alternatively, you can manually enter a
                                    secret key in your authenticator app.
                                  </v-card>
                                </v-card-actions>
                              </div>
                              <v-form
                                ref="formConfirmSignIn"
                                @submit.prevent
                                lazy-validation
                              >
                                <div class="font-weight-bold my-3">
                                  Your code
                                </div>
                                <v-text-field
                                  outlined
                                  placeholder="000000"
                                  class="borderRadiusVariant1"
                                  @keydown.space="preventLeadingSpace"
                                  :rules="[
                                    (v) => !!v || 'Code is required',
                                    (v) =>
                                      /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(
                                        v
                                      ) || 'Numeric only',
                                  ]"
                                  v-model="sign_in.code"
                                ></v-text-field>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="px-0">
                              <v-btn
                                :loading="loadingConfirmSignIn"
                                tile
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="ValidateConfirmSignInForm()"
                                >Verify</v-btn
                              >
                              <v-btn
                                text
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="loginStepper = 1"
                                >Cancel</v-btn
                              >
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="4">
                            <v-card-text class="pa-0">
                              <v-form
                                ref="formSignUp"
                                @submit.prevent
                                lazy-validation
                              >
                                <div class="font-weight-bold my-3">Name</div>
                                <v-text-field
                                  dense
                                  outlined
                                  class="borderRadiusVariant1"
                                  :rules="[(v) => !!v || 'Required']"
                                  v-model="sign_up.user_name"
                                ></v-text-field>
                                <div class="font-weight-bold mb-2 mt-n3">
                                  Email Address
                                </div>
                                <v-text-field
                                  dense
                                  outlined
                                  class="borderRadiusVariant1"
                                  @keydown.space="preventLeadingSpace"
                                  :rules="[
                                    (v) => !!v || 'Email ID is required',
                                  ]"
                                  v-model="sign_up.user_email_id"
                                ></v-text-field>
                                <div class="font-weight-bold mb-2 mt-n3">
                                  Contact Number
                                </div>
                                <v-row no-gutters>
                                  <v-col cols="12" xs="12" sm="12" md="5">
                                    <v-select
                                      outlined
                                      dense
                                      :items="GetAllCountriesList"
                                      item-value="country_code"
                                      item-text="country_dial_code"
                                      class="borderRadiusVariant1 mr-2"
                                      label="Country Code*"
                                      :rules="[(v) => !!v || 'Required']"
                                      v-model="sign_up.user_country_code"
                                    >
                                      <template
                                        slot="selection"
                                        slot-scope="{ item }"
                                      >
                                        {{ item.country_dial_code }}
                                      </template>
                                      <template
                                        slot="item"
                                        slot-scope="{ item }"
                                        >{{ item.country_name }}({{
                                          item.country_dial_code
                                        }})
                                      </template>
                                    </v-select>
                                  </v-col>
                                  <v-col cols="12" xs="12" sm="12" md="7">
                                    <v-text-field
                                      dense
                                      outlined
                                      class="borderRadiusVariant1"
                                      :rules="[(v) => !!v || 'Required']"
                                      v-model="sign_up.user_mobile_number"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <div class="font-weight-bold mb-2 mt-n3">
                                  Create a Password
                                </div>
                                <v-text-field
                                  dense
                                  outlined
                                  placeholder="Must be 8 characters or more"
                                  class="borderRadiusVariant1"
                                  :type="isPwdLogin ? 'text' : 'password'"
                                  @click:append="isPwdLogin = !isPwdLogin"
                                  @keydown.space="preventLeadingSpace"
                                  required
                                  :append-icon="
                                    isPwdLogin ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  :rules="[
                                    (v) => !!v || 'Required',
                                    (v) =>
                                      (v && v.length >= 8) ||
                                      'Password Must Have Min. 8 characters',
                                    (v) =>
                                      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).+$/.test(
                                        v
                                      ) || 'Requires Special Character',
                                  ]"
                                  v-model="sign_up.user_password"
                                ></v-text-field>
                                <v-checkbox
                                  dense
                                  class="pa-0 ma-0"
                                  label="Enable MFA"
                                  v-model="sign_up.is_mfa_enabled"
                                ></v-checkbox>
                                <v-select
                                  dense
                                  outlined
                                  v-if="sign_up.is_mfa_enabled"
                                  class="borderRadiusVariant1"
                                  label="Select MFA Type"
                                  v-model="sign_up.mfa_preference"
                                  :items="mfaTypeDropdownItems"
                                  item-text="text"
                                  item-value="value"
                                ></v-select>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="px-0">
                              <v-btn
                                :loading="loadingSignUp"
                                tile
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="ValidateSignUpForm()"
                                >Create a DealData account</v-btn
                              >
                              <v-btn
                                outlined
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="loginStepper = 1"
                                >Cancel</v-btn
                              >
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="5">
                            <v-card-text class="pa-0">
                              <div class="font-weight-bold mt-6">
                                We've send a code to your email address.
                                <span
                                  class="cursorPointer"
                                  @click="resendVerificationCode()"
                                  ><u>Please resend</u></span
                                >
                              </div>
                              <v-form
                                ref="formConfirmSignUp"
                                @submit.prevent
                                lazy-validation
                              >
                                <div class="font-weight-bold my-3">
                                  Your code
                                </div>
                                <v-text-field
                                  outlined
                                  placeholder="000000"
                                  class="borderRadiusVariant1"
                                  @keydown.space="preventLeadingSpace"
                                  :rules="[
                                    (v) => !!v || 'Code is required',
                                    (v) =>
                                      /^(?=[a-zA-Z0-9@#._%+-]{0,30}$)[-0-9]{0,64}$/.test(
                                        v
                                      ) || 'Numeric only',
                                  ]"
                                  v-model="sign_up.code"
                                ></v-text-field>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="px-0">
                              <v-btn
                                :loading="loadingConfirmSignUp"
                                tile
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="ValidateConfirmSignUpForm()"
                                >Verify</v-btn
                              >
                              <v-btn
                                text
                                class="borderRadiusVariant2 text-capitalize px-6"
                                color="primary"
                                @click="loginStepper = 1"
                                >Cancel</v-btn
                              >
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="6">
                            <v-card-text class="pa-0">
                              <v-form ref="formConfirmTrustedDeviceOTP">
                                <div
                                  class="fontWeightVariant1 white--text lineHeightVariant1 text-left mt-4"
                                >
                                  Please enter the OTP that was sent to your
                                  registered email: {{ maskedEmail }}
                                </div>
                                <div class="mt-5">
                                  <v-otp-input
                                    :length="6"
                                    :rules="[(v) => !!v || 'OTP is required']"
                                    type="number"
                                    v-model="TrustedDeviceOTP"
                                  ></v-otp-input>
                                </div>
                              </v-form>

                              <v-card-actions class="pa-0 ma-0">
                                <v-btn
                                  depressed
                                  block
                                  dark
                                  class="text-capitalize mt-4 ml-n1"
                                  :loading="TrustedDeviceOTPLoading"
                                  color="primary"
                                  @click="ValidateTrustedDeviceOTPMethod()"
                                  >Verify OTP</v-btn
                                >
                              </v-card-actions>
                              <v-row class="mt-2">
                                <v-col cols="12" align="center">
                                  <!-- <div v-if="ResendOTPCounter !== 0" class="white--text">
                                    Resend OTP available in {{ ResendOTPCounter }}
                                  </div> -->
                                  <v-btn
                                    text
                                    :disabled="ResendOTPCounter !== 0"
                                    :dark="ResendOTPCounter !== 0"
                                    color="white"
                                    :loading="loadingSignIn"
                                    class="white--text pa-0 ml-0"
                                    :class="
                                      ResendOTPCounter === 0
                                        ? 'text-decoration-underline'
                                        : ''
                                    "
                                    @click="signInMethod()"
                                    >Resend OTP<span
                                      v-if="ResendOTPCounter !== 0"
                                      class="pl-2 greyColorVariant4--text"
                                      >{{ ResendOTPCounter }}</span
                                    ></v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-window-item>
                          <v-window-item :value="7">
                            <v-card-text>
                              <v-form ref="formOTP">
                                <div class="font-weight-bold white--text my-3">
                                  Please Enter The Registered Email
                                </div>
                                <v-text-field
                                  dense
                                  outlined
                                  background-color="white"
                                  placeholder="Email"
                                  v-model="ForgotPassword.OTPEmail"
                                  :rules="[
                                    (v) => !!v || 'Email ID is required',
                                  ]"
                                  class="mt-4 borderRadiusVariant1"
                                >
                                </v-text-field>
                              </v-form>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn
                                text
                                color="white"
                                small
                                @click="loginStepper = 1"
                                >Back To Sign In</v-btn
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="white"
                                small
                                :loading="ForgotPasswordOTPLoading"
                                @click="ValidateOTP()"
                                >Next</v-btn
                              >
                            </v-card-actions>
                          </v-window-item>
                          <v-window-item :value="8">
                            <v-card-text>
                              <v-form ref="formConfirmOTP">
                                <div class="white--text font-weight-bold my-1">
                                  Enter OTP
                                </div>
                                <v-otp-input
                                  length="6"
                                  v-model="ForgotPassword.ConfirmOTP"
                                  :rules="[(v) => !!v || 'Code is required']"
                                  class="mt-4"
                                >
                                </v-otp-input>
                                <div class="white--text font-weight-bold my-1">
                                  Enter New Password *
                                </div>
                                <v-text-field
                                  background-color="white"
                                  dense
                                  outlined
                                  v-model="ForgotPassword.ConfirmNewPassword"
                                  :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]"
                                  class=""
                                  :type="isPwdConfirmCode ? 'text' : 'password'"
                                  @click:append="
                                    isPwdConfirmCode = !isPwdConfirmCode
                                  "
                                  required
                                  :append-icon="
                                    isPwdConfirmCode ? 'mdi-eye' : 'mdi-eye-off'
                                  "
                                  prepend-inner-icon="mdi-lock"
                                >
                                </v-text-field>
                                <div class="white--text font-weight-bold my-1">
                                  Re-enter New Password *
                                </div>
                                <v-text-field
                                  dense
                                  outlined
                                  background-color="white"
                                  v-model="ForgotPassword.ReenterNewPassword"
                                  :rules="[
                                    (v) => !!v || 'Password is required',
                                  ]"
                                  class=""
                                  :type="
                                    isPwdReConfirmCode ? 'text' : 'password'
                                  "
                                  @click:append="
                                    isPwdReConfirmCode = !isPwdReConfirmCode
                                  "
                                  required
                                  :append-icon="
                                    isPwdReConfirmCode
                                      ? 'mdi-eye'
                                      : 'mdi-eye-off'
                                  "
                                  prepend-inner-icon="mdi-lock"
                                >
                                </v-text-field>
                              </v-form>
                            </v-card-text>
                            <v-card-actions class="py-0">
                              <v-btn
                                text
                                color="white"
                                small
                                @click="loginStepper = 1"
                                >Back To Sign In</v-btn
                              >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="white"
                                small
                                :loading="ForgotPasswordFinalSteploading"
                                @click="ValidateEnteredOTP()"
                                >submit</v-btn
                              >
                            </v-card-actions>
                          </v-window-item>
                        </v-window>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-flex>
          </v-layout>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
import { GetUniqueId } from "@/mixins/MultiFactorAuthentication/GetUniqueId.js";
import { GetVerifiedTrustedDevice } from "@/mixins/MultiFactorAuthentication/GetVerifiedTrustedDevice.js";
import { SendTrustedDeviceOTP } from "@/mixins/MultiFactorAuthentication/SendTrustedDeviceOTP.js";
import { VerifyTrustedDeviceOTP } from "@/mixins/MultiFactorAuthentication/VerifyTrustedDeviceOTP.js";
import { AddTrustedDeviceUser } from "@/mixins/MultiFactorAuthentication/AddTrustedDeviceUser.js";
Vue.component("qr-code", VueQRCodeComponent);
import { Auth } from "aws-amplify";

import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  mixins: [
    GetUniqueId,
    GetVerifiedTrustedDevice,
    SendTrustedDeviceOTP,
    VerifyTrustedDeviceOTP,
    AddTrustedDeviceUser,
  ],
  data: () => ({
    qrCodeURL: "",
    connectionStatus: "NOT_CONNECTED",
    loginStepper: 1,
    unique_id: "",
    TrustedDeviceOTP: "",
    ResendOTPCounter: 0,
    intervalId: null,

    loading: false,
    isPwdLogin: false,
    isPwdConfirmCode: false,
    isPwdReConfirmCode: false,

    loadingSignIn: false,
    loadingSignUp: false,
    qrCodeRequired: false,
    loadingConfirmSignIn: false,
    loadingConfirmSignUp: false,
    IsDeviceTrusted: false,
    TrustedDeviceOTPLoading: false,
    ForgotPasswordFinalSteploading: false,
    ForgotPasswordOTPLoading: false,

    ForgotPassword: {
      OTPEmail: "",
      ConfirmNewPassword: "",
      ReenterNewPassword: "",
      ConfirmOTP: "",
    },

    sign_in: {
      user_email_id: "",
      user_password: "",
      code: "",
    },
    sign_up: {
      user_name: "",
      user_email_id: "",
      user_password: "",
      code: "",
      user_country_code: "",
      user_mobile_number: "",
      is_mfa_enabled: false,
      mfa_preference: "",
    },
    userObject: {},
    SnackBarComponent: {},
    GetAllCountriesList: [
      {
        country_name: "USA",
        country_dial_code: "+1",
      },
    ],
    mfaTypeDropdownItems: [
      {
        text: "SMS",
        value: "SMS",
      },
      {
        text: "Email",
        value: "EMAIL",
      },
    ],
  }),
  watch: {
    "sign_in.user_email_id"(val) {
      this.sign_in.user_email_id = val.toLowerCase();
    },
    loginStepper(value) {
      switch (value) {
        case 1:
          if (this.$refs.formSignUp) {
            this.$refs.formSignUp.reset();
          }
          if (this.$refs.formConfirmSignUp) {
            this.$refs.formConfirmSignUp.reset();
          }
          break;
        case 4:
          if (this.$refs.formSignIn) {
            this.$refs.formSignIn.reset();
          }
          if (this.$refs.formSignUp) {
            this.$refs.formSignUp.reset();
          }
          if (this.$refs.formConfirmSignUp) {
            this.$refs.formConfirmSignUp.reset();
          }
          this.sign_up.user_country_code = "+1";
          break;
      }
    },
  },
  computed: {
    currentTitle() {
      switch (this.loginStepper) {
        case 1:
          return window.location.hostname === "dev.commdealdata.com"
            ? "Welcome to DealData - DEV"
            : window.location.hostname === "test.commdealdata.com"
            ? "Welcome to DealData - TEST"
            : window.location.hostname === "uat.commdealdata.com"
            ? "Welcome to DealData - UAT"
            : "Welcome to DealData";
        case 2:
          return window.location.hostname === "dev.commdealdata.com"
            ? "Welcome to DealData - DEV"
            : window.location.hostname === "test.commdealdata.com"
            ? "Welcome to DealData - TEST"
            : window.location.hostname === "uat.commdealdata.com"
            ? "Welcome to DealData - UAT"
            : "Welcome to DealData";
        case 5:
          return "Please check your email";
        default:
          return "Create a DealData account to submit and analyze deals";
      }
    },
    maskedEmail() {
      if (this.sign_in.user_email_id) {
        const [prefix, domain] = this.sign_in.user_email_id.split("@");
        const maskedPrefix = `${prefix.substring(0, 2)}******`;
        return `${maskedPrefix}@${domain}`;
      }
      return this.sign_in.user_email_id;
    },
  },

  mounted() {
    console.log(this.$router.history);
    localStorage.clear();
    this.disableHistoryChanges();
    this.unique_id = this.getUniqueIdMethod();
    this.GetVerifiedTrustedDeviceMethod(this.unique_id);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    ResendOTPCounterMethod() {
      this.intervalId = setInterval(() => {
        this.ResendOTPCounter--;
        if (this.ResendOTPCounter <= 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
    disableHistoryChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    ValidateConfirmSignInForm() {
      if (this.$refs.formConfirmSignIn.validate()) {
        if (this.qrCodeRequired) {
          this.confirmSignInMethod();
        } else {
          this.confirmFinalSignInMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async confirmSignInMethod() {
      try {
        this.loadingConfirmSignIn = true;
        await Auth.verifyTotpToken(this.userObject, this.sign_in.code);
        let result1 = await Auth.setPreferredMFA(this.userObject, "TOTP");
        if (result1 == "SUCCESS") {
          this.loginStepper = 1;
          this.loadingConfirmSignIn = false;
          this.$refs.formConfirmSignIn.reset();
        }
      } catch (error) {
        this.loadingConfirmSignIn = false;
      }
    },
    async confirmFinalSignInMethod() {
      try {
        this.loadingConfirmSignIn = true;
        await Auth.confirmSignIn(
          this.userObject,
          this.sign_in.code,
          "SOFTWARE_TOKEN_MFA"
        );
        this.loadingConfirmSignIn = false;
        this.pushToHomeRouteMethod();
      } catch (error) {
        this.loadingConfirmSignIn = false;
      }
    },
    async ValidateTrustedDeviceOTPMethod() {
      if (this.$refs.formConfirmTrustedDeviceOTP.validate()) {
        try {
          this.TrustedDeviceOTPLoading = true;
          await Auth.sendCustomChallengeAnswer(this.user, this.TrustedDeviceOTP)
            .then((res) => {
              this.pushToHomeRouteMethod();
            })
            .catch((err) => {
              this.TrustedDeviceOTP = "";
              if (err.message === "Invalid session for the user.") {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "OTP Expired",
                };
              } else if (
                err.message ===
                "VerifyAuthChallengeResponse failed with error Incorrect OTP!!."
              ) {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: "Incorrect OTP",
                };
              } else {
                this.SnackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  Top: true,
                  SnackbarText: err.message,
                };
              }
              this.TrustedDeviceOTPLoading = false;
            });
          this.TrustedDeviceOTPLoading = false;
        } catch (error) {
          console.log("error", error);

          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.message,
          };
          this.loadingPassword = false;
        }
      }
    },
    ValidateSignInForm() {
      if (this.$refs.formSignIn.validate()) {
        this.signInMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async signInMethod() {
      try {
        this.loadingSignIn = true;
        this.user = await Auth.signIn({
          username: this.sign_in.user_email_id,
          password: this.sign_in.user_password,
          attributes: {
            customAuthFlow: "USER_SRP_AUTH",
          },
        });
        this.user = await Auth.signIn(this.sign_in.user_email_id);
        this.$store.commit("SET_USEREMAIL", this.sign_in.user_email_id);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "OTP sent successful",
        };
        this.ResendOTPCounter = 120;
        this.ResendOTPCounterMethod();
        this.loginStepper = 6;
        this.loadingSignIn = false;
      } catch (error) {
        console.log("Error", error);
        if (error.message === "Incorrect username or password.") {
          // this.loginStepper = 7;
          this.loadingSignIn = true;
          this.SignUpCognitoMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.message,
          };
          this.loadingSignIn = false;
        }
      }
      // try {
      //   this.loadingSignIn = true;
      //   this.qrCodeRequired = false;
      //   this.userObject = await Auth.signIn(
      //     this.sign_in.user_email_id,
      //     this.sign_in.user_password
      //   );
      //   this.$store.commit("SET_USEREMAIL", this.sign_in.user_email_id);
      //   if (this.userObject.challengeName === "MFA_SETUP") {
      //     const res = await Auth.setupTOTP(this.userObject);
      //     this.qrCodeURL =
      //       "otpauth://totp/AWSCognito:" +
      //       this.sign_in.user_email_id +
      //       "?secret=" +
      //       res +
      //       "&issuer=Cognito";
      //     this.loginStepper = 2;
      //     this.qrCodeRequired = true;
      //   } else if (this.userObject.challengeName === "SOFTWARE_TOKEN_MFA") {
      //     this.loginStepper = 2;
      //     this.qrCodeRequired = false;
      //   } else if (
      //     this.IsDeviceTrusted === false ||
      //     !this.GetTrustedDeviceUserEmailIds.includes(
      //       this.sign_in.user_email_id
      //     )
      //   ) {
      //     let Result = await this.SendTrustedDeviceOTPMethod(
      //       this.sign_in.user_email_id
      //     );
      //     if (Result.status === "Success") {
      //       this.loginStepper = 6;
      //     } else {
      //       this.loadingSignIn = false;
      //     }
      //   } else if (
      //     this.IsDeviceTrusted === true &&
      //     this.GetTrustedDeviceUserEmailIds.includes(this.sign_in.user_email_id)
      //   ) {
      //     this.$store.commit("SET_USEREMAIL", this.sign_in.user_email_id);
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "green",
      //       Top: true,
      //       SnackbarText: "Login Successful",
      //     };
      //     this.pushToHomeRouteMethod();
      //   }
      //   this.loadingSignIn = false;
      // } catch (error) {
      //   this.loadingSignIn = false;
      //   if (error.toString().includes("UserNotConfirmedException")) {
      //     await Auth.resendSignUp(this.sign_in.user_email_id);
      //     this.sign_up.user_email_id = this.sign_in.user_email_id;
      //     this.loginStepper = 5;
      //   } else {
      //     this.SnackBarComponent = {
      //       SnackbarVmodel: true,
      //       SnackbarColor: "red",
      //       Top: true,
      //       SnackbarText: error.message,
      //     };
      //   }
      // }
    },
    async SignUpCognitoMethod() {
      try {
        this.loadingSignIn = true;
        let result = await Auth.signUp(
          this.sign_in.user_email_id,
          this.sign_in.user_password
        );
        this.signInMethod();
      } catch (error) {
        console.log("check Error Cognito SignUp", error);
        this.loadingSignIn = false;
        if (
          error.message === "An account with the given email already exists."
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Incorrect username or password.",
          };
        }
      }
    },
    ValidateSignUpForm() {
      if (this.$refs.formSignUp.validate()) {
        this.signUpMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async signUpMethod() {
      // try {
      //   this.loadingSignUp = true;
      //   this.userObject = await Auth.signUp({
      //     username: this.sign_up.user_email_id.toLowerCase().trim(),
      //     password: this.sign_up.user_password,
      //     attributes: {
      //       "custom:user_name": this.sign_up.user_name,
      //       "custom:user_country_code": this.sign_up.user_country_code,
      //       "custom:user_mobile_number": this.sign_up.user_mobile_number,
      //       "custom:is_mfa_enabled": this.sign_up.is_mfa_enabled
      //         ? this.sign_up.is_mfa_enabled.toString()
      //         : "false",
      //       "custom:mfa_preference": this.sign_up.is_mfa_enabled
      //         ? this.sign_up.mfa_preference
      //         : undefined,
      //     },
      //     autoSignIn: {
      //       enabled: true,
      //     },
      //   });
      //   this.$store.commit("SET_USEREMAIL", this.sign_up.user_email_id);
      //   this.loadingSignUp = false;
      //   this.loginStepper = 5;
      // } catch (error) {
      //   this.loadingSignUp = false;
      //   this.SnackBarComponent = {
      //     SnackbarVmodel: true,
      //     SnackbarColor: "red",
      //     Top: true,
      //     SnackbarText: error.toString().split(":")[1],
      //   };
      // }
    },
    ValidateConfirmSignUpForm() {
      if (this.$refs.formConfirmSignUp.validate()) {
        this.confirmSignUp();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Mandatory Fields",
        };
      }
    },
    async confirmSignUp() {
      try {
        this.loadingConfirmSignUp = true;
        await Auth.confirmSignUp(this.sign_up.user_email_id, this.sign_up.code);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Successfully Signed Up !!",
        };
        this.loginStepper = 1;
        if (this.$refs.formSignUp) {
          this.$refs.formSignUp.reset();
        }
        if (this.$refs.formConfirmSignUp) {
          this.$refs.formConfirmSignUp.reset();
        }
        if (this.$refs.formSignIn) {
          this.$refs.formSignIn.reset();
        }
        this.loadingConfirmSignUp = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.toString().split(":")[1],
        };
        this.loadingConfirmSignUp = false;
      }
    },
    async resendVerificationCode() {
      try {
        await Auth.resendSignUp(this.sign_up.user_email_id);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Code Successfully Sent",
        };
      } catch (err) {
        throw err;
      }
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    pushToHomeRouteMethod() {
      this.loadingSignIn = false;
      this.TrustedDeviceOTPLoading = false;
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Successfully Logged In",
      };
      this.$router.push("/MainHomePages/LandingPage");
      setTimeout(() => {}, 2000);
    },
    ValidateOTP() {
      if (this.$refs.formOTP.validate()) {
        this.SendOTPEmail();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Registered Email",
        };
      }
    },
    SendOTPEmail() {
      this.ForgotPasswordOTPLoading = true;
      Auth.forgotPassword(this.ForgotPassword.OTPEmail)
        .then(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "OTP Sent To Registered Email",
          };
          this.ForgotPasswordOTPLoading = false;
          this.loginStepper = 8;
        })
        .catch((err) => {
          this.ForgotPasswordOTPLoading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
    ValidateEnteredOTP() {
      if (this.$refs.formConfirmOTP.validate()) {
        if (this.ReenterNewPassword == this.ConfirmNewPassword) {
          this.ConfirmOTPMethod();
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Password Does not match",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter The Code Sent To Registered Email",
        };
      }
    },
    ConfirmOTPMethod() {
      this.ForgotPasswordFinalSteploading = true;
      Auth.forgotPasswordSubmit(
        this.ForgotPassword.OTPEmail,
        this.ForgotPassword.ConfirmOTP,
        this.ForgotPassword.ConfirmNewPassword
      )
        .then(() => {
          this.$refs.formConfirmOTP.reset();
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Password Successfully Changed ",
          };
          this.ForgotPasswordFinalSteploading = false;
          this.loginStepper = 1;
        })
        .catch((err) => {
          this.ForgotPasswordFinalSteploading = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: err.message,
          };
        });
    },
  },
};
</script>

<style>
.background {
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  background: linear-gradient(132deg, #001943, #023a9c, #0019436e);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
}
.background .bg-img {
  top: 0 !important;
  bottom: 0 !important;
  min-height: 10vh !important;
  z-index: 999 !important;
  opacity: 1 !important;
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* padding: 30px !important; */
  overflow: hidden !important;
  background: #fff !important;
  border-radius: 0 0 21.875rem 0 !important;
}
.FontSize65px {
  font-size: 65px !important;
}
.FontSize45px {
  font-size: 45px !important;
}
.FontSize35px {
  font-size: 35px !important;
}
.GradientText {
  font-size: 35px !important;
  font-weight: 600 !important;
  background-image: linear-gradient(to right, #0354e0, #81a9ef) !important;
  background-size: 100%;
  -webkit-background-clip: text !important;
  -moz-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}
.lineUp {
  animation: 3s anim-lineUp ease-out;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.lineUp2 {
  animation: 4s lineup2 ease-out !important;
}
@keyframes lineup2 {
  0% {
    opacity: 0;
    transform: translateX(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
