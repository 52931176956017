import axios from "axios";
export const purposeMaster = {
  data() {
    return {
      purposeMastersList: [],
      purposeMastersWithAllList: [],
      overlay: false,
    };
  },
  mounted() {},
  methods: {
    async getpurposeMasterListMethod() {
      try {
        let data = JSON.stringify({});
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicPurposeMasterRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };
        console.log('i m a puropose type',this.getDynamicPurposeMasterRestAPIUrlMethod())
        let result = await axios(config);
        if (
          result.status == 200 &&
          result.data &&
          result.data.status &&
          result.data.status == "Success"
        ) {
          this.purposeMastersList = result.data.data.Items.map(
            (commonObj) => commonObj.purpose_type_name
          );
          for (const purposeMaster of this.purposeMastersList) {
            this.purposeMastersWithAllList.push(purposeMaster);
          }
          this.purposeMastersWithAllList.unshift("All");
        }
      } catch (error) {
        throw error;
      }
    },
    getDynamicPurposeMasterRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/listpurposetypes"
        : window.location.hostname === "test.commdealdata.com" ||
          window.location.hostname == "localhost"
        ? "https://n39lic7m7f.execute-api.us-east-1.amazonaws.com/test/listpurposetypes"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://9u85ekg1ph.execute-api.us-east-1.amazonaws.com/uat/listpurposetypes"
        : "https://jy4pdmjen6.execute-api.us-east-1.amazonaws.com/live/listpurposetypes";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com" ||
          window.location.hostname == "localhost"
        ? "wb8Ax8u4Qz1Xql79HrdIL2sAz3Q1YYhd21fqnqeh"
        : window.location.hostname === "uat.commdealdata.com"
        ? "bSlHynoh4768l5z5wJfDf2b39waVenHoayCQb1dY"
        : "0vkwAnXk893XPOWaH5Aqt97wWHsnnX3Q8DNg328z";
    },
  },
};
