import axios from "axios";
export const SendReportIssue = {
  data: () => ({}),
  methods: {
    async SendReportIssueMethod(
      from_user_email_id,
      to_user_email_id,
      body,
      bank_details
    ) {
      try {
        this.loading = true;
        let data = JSON.stringify({
          command: "reportIssues",
          from_user_email_id,
          to_user_email_id,
          body,
          bank_details,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicReportIssueRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };

        let result = await axios(config);
        this.loading = false;
        return result;
      } catch (error) {
        this.loading = false;
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
    getDynamicReportIssueRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/report_issues"
        : window.location.hostname === "test.commdealdata.com"
        ? "https://n39lic7m7f.execute-api.us-east-1.amazonaws.com/test/report_issues"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://9u85ekg1ph.execute-api.us-east-1.amazonaws.com/uat/report_issues"
        : "https://jy4pdmjen6.execute-api.us-east-1.amazonaws.com/live/report_issues";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com"
        ? "wb8Ax8u4Qz1Xql79HrdIL2sAz3Q1YYhd21fqnqeh"
        : window.location.hostname === "uat.commdealdata.com"
        ? "bSlHynoh4768l5z5wJfDf2b39waVenHoayCQb1dY"
        : "0vkwAnXk893XPOWaH5Aqt97wWHsnnX3Q8DNg328z";
    },
  },
};
