import axios from "axios";

export const GetVerifiedTrustedDevice = {
  data: () => ({
    GetTrustedDeviceUserEmailIds: [],
  }),
  methods: {
    async GetVerifiedTrustedDeviceMethod(browser_id) {
      try {
        let data = JSON.stringify({
          browser_id,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicVerifyTrustedDeviceRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };
        let result = await axios(config);
        if (
          result.status == 200 &&
          result.data &&
          result.data.status &&
          result.data.status == "Success"
        ) {
          this.IsDeviceTrusted = true;
          this.GetTrustedDeviceUserEmailIds = result.data.data.map(
            (item) => item.user_email_id
          );
        }
      } catch (error) {
        this.IsDeviceTrusted = false;
        console.log("Error In LIST API CLass", error);
      }
    },
    getDynamicVerifyTrustedDeviceRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/checkuserstrusteddevicebrowsers"
        : window.location.hostname === "test.commdealdata.com"
        ? "https://n39lic7m7f.execute-api.us-east-1.amazonaws.com/test/checkuserstrusteddevicebrowsers"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://9u85ekg1ph.execute-api.us-east-1.amazonaws.com/uat/checkuserstrusteddevicebrowsers"
        : "https://jy4pdmjen6.execute-api.us-east-1.amazonaws.com/live/checkuserstrusteddevicebrowsers";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com"
        ? "wb8Ax8u4Qz1Xql79HrdIL2sAz3Q1YYhd21fqnqeh"
        : window.location.hostname === "uat.commdealdata.com"
        ? "bSlHynoh4768l5z5wJfDf2b39waVenHoayCQb1dY"
        : "0vkwAnXk893XPOWaH5Aqt97wWHsnnX3Q8DNg328z";
    },
  },
};
