import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import "@/plugins/apexcharts";
import { Amplify } from "aws-amplify";
Vue.config.productionTip = false;
/**/
Amplify.configure({
  Auth: {
    userPoolId:
      window.location.hostname === "dev.commdealdata.com"
        ? "us-east-1_ETgwsQ3zu"
        : window.location.hostname === "test.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "us-east-1_OwaiYs3Lb"
        : window.location.hostname === "uat.commdealdata.com"
        ? "us-east-1_rS56nrtPi"
        : "us-east-1_Gkh7ez0Uq",
    region: "us-east-1",
    userPoolWebClientId:
      window.location.hostname === "dev.commdealdata.com"
        ? "r56ol9qejbjdafjinqitd9m06"
        : window.location.hostname === "test.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "6jebv5bhirm09tmss2akenut49" 
        : window.location.hostname === "uat.commdealdata.com"
        ? "2l0urgg0l5o01ik630ik24k0bc"
        : "3quqdu6aafq2ufqdf0uv10a9kn",
  },
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint:
      window.location.hostname === "dev.commdealdata.com"
        ? "https://rqsep4qswbfr5buxfmuiwv4fja.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "test.commdealdata.com" ||  window.location.hostname == 'localhost'
        ? "https://szlcjbwi7zbddlnqsrtmw7hxnm.appsync-api.us-east-1.amazonaws.com/graphql"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://dedjjototzghznqr33lhbxgu5y.appsync-api.us-east-1.amazonaws.com/graphql"
        : "https://fpa7l6i3ojerrkast2s4fj4thu.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    endpoints: [
      {
        name: "DEALHOUR",
        endpoint: "https://9u85ekg1ph.execute-api.us-east-1.amazonaws.com/uat/",
      },
    ],
  },
});
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
