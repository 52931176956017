import Vue from "vue";
import VueRouter from "vue-router";
import MainLoginPage from "../views/MainHomePages/MainLoginPage.vue";
import LoginPage from "../views/MainHomePages/LoginPage.vue";
import SignUpPage from "../views/MainHomePages/SignUpPage.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/SIGNUP/:EditorViewerSignUp",
    name: "SignUp",
    component: SignUpPage,
  },
  {
    path: "/BANKONBOARDING/:InvitedMemberDetails",
    name: "MainLoginPage",
    component: MainLoginPage,
  },
  {
    path: "/MainHomePages/LandingPage",
    name: "LandingPage",
    component: () =>
      import(
        /* webpackChunkName: "LandingPage" */ "../views/MainHomePages/LandingPage.vue"
      ),
    children: [
      {
        path: "/MainHomePages/MyDeals",
        name: "/MainHomePages/MyDeals",
        component: () =>
          import(
            /* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/MyDeals.vue"
          ),
      },
      {
        path: "/MainHomePages/BankProfiles",
        name: "/MainHomePages/BankProfiles",
        component: () =>
          import(
            /* webpackChunkName: "MyDeals" */ "../views/ChildHomePages/BankProfiles.vue"
          ),
      },
      {
        path: "/MainHomePages/Volume",
        name: "/MainHomePages/Volume",
        component: () =>
          import(
            /* webpackChunkName: "Volume" */ "../views/ChildHomePages/Volume.vue"
          ),
      },
      {
        path: "/MainHomePages/IndicativeTerms",
        name: "/MainHomePages/IndicativeTerms",
        component: () =>
          import(
            /* webpackChunkName: "IndicativeTerms" */ "../views/ChildHomePages/IndicativeTerms.vue"
          ),
      },
      {
        path: "/MainHomePages/Drilldown",
        name: "/MainHomePages/Drilldown",
        component: () =>
          import(
            /* webpackChunkName: "Drilldown" */ "../views/ChildHomePages/Drilldown.vue"
          ),
      },
      {
        path: "/MainHomePages/TeamMembers",
        name: "/MainHomePages/TeamMembers",
        component: () =>
          import(
            /* webpackChunkName: "TeamMembers" */ "../views/ChildHomePages/TeamMembers.vue"
          ),
      },

      {
        path: "/MainHomePages/AuditLogs",
        name: "/MainHomePages/AuditLogs",
        component: () =>
          import(
            /* webpackChunkName: "MyAccount" */ "../views/ChildHomePages/AuditLogs.vue"
          ),
      },
      {
        path: "/MainHomePages/MyBankProfile",
        name: "/MainHomePages/MyBankProfile",
        component: () =>
          import(
            /* webpackChunkName: "MyAccount" */ "../views/ChildHomePages/MyBankProfile.vue"
          ),
      },
    ],
  },
  {
    path: "/Notifications",
    name: "Notifications",
    component: () =>
      import(
        /* webpackChunkName: "Notifications" */ "../views/MainHomePages/Notifications.vue"
      ),
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;


