export const formatNumberWithCommas = {
  data: () => ({}),
  mounted() {},
  methods: {
    formatNumberWithCommasMethod(number) {
      const formattedNumber = number.toLocaleString("en-US");
      return formattedNumber;
    },
    formatDollarAmountWithCommasMethod(number) {
      const parsedNumber = parseFloat(number.replace(/[^0-9.]/g, ""));
      if (isNaN(parsedNumber)) {
        return "";
      }
      const formattedNumber = parsedNumber.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      });
      return formattedNumber;
    },
  },
};
