<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog
      persistent
      transition="dialog-top-transition"
      v-model="ContactUsDialog"
      width="420px"
      content-class="DialogOpenFromTop"
    >
      <v-card>
        <v-toolbar height="40" dense class="elevation-0">
          <div class="fontSize25px font-weight-bold pt-4">Report</div>
          <v-spacer />
          <v-btn icon class="mt-4" @click="ContactUsDialogEmit(1)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pb-0">
          <v-card flat>
            <v-form ref="form">
              <div class="font-weight-bold mt-6 mb-2">
                From <Span class="red--text">*</Span>
              </div>
              <v-text-field
                dense
                outlined
                readonly
                disabled
                class="borderRadiusVariant1 fontSize15px"
                :rules="[(v) => !!v || 'Required']"
                v-model="SendReport.FromAddress"
              ></v-text-field>
              <div class="font-weight-bold mb-2">
                To <Span class="red--text">*</Span>
              </div>
              <v-text-field
                dense
                outlined
                readonly
                disabled
                class="borderRadiusVariant1 fontSize15px"
                :rules="[(v) => !!v || 'Required']"
                v-model="SendReport.ToAddress"
              ></v-text-field>
              <div class="font-weight-bold mb-2">
                Message <Span class="red--text">*</Span>
              </div>
              <div id="BankDetails">
                <div v-show="HiddenDetails">jjooooo</div>
                <v-textarea
                  :rows="6"
                  outlined
                  :rules="[(v) => !!v || 'Required']"
                  v-model="SendReport.Message"
                ></v-textarea>
              </div>
            </v-form>
          </v-card>
          <v-card-actions class="py-0 pb-1">
            <v-spacer />
            <v-btn
              class="text-capitalize borderRadiusVariant2 px-4"
              color="primary"
              dark
              depressed
              :loading="loading"
              @click="ValidateMethod()"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { SendReportIssue } from "@/mixins/RestAPIs/SendReportIssue.js";
export default {
  props: {
    ContactUsDialog: Boolean,
    currentTeamDetailsObject: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [SendReportIssue],
  data: () => ({
    loading: false,
    HiddenDetails: false,

    SnackBarComponent: {},
    SendReport: {
      FromAddress: "",
      ToAddress: "",
      Message: "",
    },
  }),
  watch: {
    ContactUsDialog(val) {
      if (val == true) {
        this.SendReport.FromAddress =
          this.currentTeamDetailsObject.bank_contact_person_email_id;
        this.SendReport.ToAddress = "support@cmlabt.com";
      }
    },
  },
  methods: {
    async ValidateMethod() {
      if (this.$refs.form.validate()) {
        let BankObject = {
          bank_id: this.currentTeamDetailsObject.bank_id,
          bank_name: this.currentTeamDetailsObject.bank_name,
        };
        let result = await this.SendReportIssueMethod(
          this.SendReport.FromAddress,
          this.SendReport.ToAddress,
          this.SendReport.Message,
          BankObject
        );
        if (result.data.$metadata.httpStatusCode == 200) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: "Issue Reported Successfully",
          };
          this.ContactUsDialogEmit(1);
        } else if (result.statusText == "FAILURE") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: result.status,
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Message is required",
        };
      }
    },
    ContactUsDialogEmit(Toggle) {
      this.$refs.form.reset();
      this.$emit("clicked", Toggle);
    },
  },
};
</script>

