import axios from "axios";
export const VerifyCodeAndFetchBankDetails = {
  data: () => ({}),
  methods: {
    async VerifyCodeAndFetchBankDetailsMethod(user_email_id, otp, bank_id) {
      try {
        let data = JSON.stringify({
          command: "verifyOTPAndGetBankDetails",
          user_email_id,
          otp,
          bank_id,
        });
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: this.getDynamicVerifyCodeAndFetchBankDetailsRestAPIUrlMethod(),
          headers: {
            "Content-Type": "application/json",

            "x-api-key": this.getDynamicRestAPIKeyMethod(),
          },
          data: data,
        };

        let result = await axios(config);
        return result;
      } catch (error) {
        return {
          status: error.response.status,
          statusText: "FAILURE",
          error: error.response.data.Model,
        };
      }
    },
    getDynamicVerifyCodeAndFetchBankDetailsRestAPIUrlMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "https://7mw4ny54cc.execute-api.us-east-1.amazonaws.com/dev/verifyotpandfetchbank"
        : window.location.hostname === "test.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "https://n39lic7m7f.execute-api.us-east-1.amazonaws.com/test/verifyotpandfetchbank"
        : window.location.hostname === "uat.commdealdata.com"
        ? "https://9u85ekg1ph.execute-api.us-east-1.amazonaws.com/uat/verifyotpandfetchbank"
        : "https://jy4pdmjen6.execute-api.us-east-1.amazonaws.com/live/verifyotpandfetchbank";
    },
    getDynamicRestAPIKeyMethod() {
      return window.location.hostname === "dev.commdealdata.com"
        ? "Df77h95pAo76hNjGGxjQTaYEiaN0CR2z179805yW"
        : window.location.hostname === "test.commdealdata.com"  ||  window.location.hostname == 'localhost'
        ? "wb8Ax8u4Qz1Xql79HrdIL2sAz3Q1YYhd21fqnqeh"
        : window.location.hostname === "uat.commdealdata.com"
        ? "bSlHynoh4768l5z5wJfDf2b39waVenHoayCQb1dY"
        : "0vkwAnXk893XPOWaH5Aqt97wWHsnnX3Q8DNg328z";
    },
  },
};
